<template>
  <div class="news-container">
    <NewsInfo />
  </div>
</template>

<script>

import NewsInfo from '@/components/about/NewsInfo.vue'

export default {
  name: 'News',

  components: {
    NewsInfo,
  },

  data () {
    return {
    }
  },
  methods: {
  }

}

</script>

<style lang="scss" scoped>

.news-container {
  flex: 1;
  text-align: left;
}

</style>
