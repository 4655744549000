<template>
  <div class="news-info">
    <div @click="showArticleDetail('1')" class="news-item">
      <img class="news-icon" src="@/assets/images/cover_01.png" alt="">
      <div class="news-container">
        <h3 class="news-title">垃圾分类就是新时尚 | 群峰行动</h3>
        <p class="news-description">
          为响应政府号召，培养居民生活垃圾日常分类习惯，促进生活垃圾分类减量的目的，群峰一直在探索新的模式，“童子街庭苑”就是其中一项新的举措...
        </p>
        <p class="news-time">2021/06/10</p>
      </div>
    </div>
    <div @click="showArticleDetail('2')" class="news-item">
      <img class="news-icon" src="@/assets/images/cover_02.png" alt="">
      <div class="news-container">
        <h3 class="news-title">镜头下，童子街庭苑居民怎么说</h3>
        <p class="news-description">
          昨日（3月25日）成都电视台的记者来到童子街庭苑，了解智能垃圾回收箱的使用情况，并且采访了小区的居民，那么实际情况怎么样呢？...
        </p>
        <p class="news-time">2021/03/26</p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'NewsInfo',
  data () {
    return {

    }
  },
  methods: {
    // 显示文章详情
    showArticleDetail (id) {
      this.$emit('onShowArticleDetail', id)
      this.$router.push({ path: '/about/newsDetail?id=' + id })
    }
  }

}

</script>

<style lang="scss" scoped>

.news-info {
  flex: 1;
  width: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;
  .news-item {
    margin-bottom: 45px;
    flex: 0 0 auto;
    width: 100%;
    height: 130px;
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .news-icon {
      margin-right: 20px;
      flex: 0 0 auto;
      width: 230px;
      height: 130px;
    }
    .news-container {
      flex: 1;
      display: flex;
      flex-direction: column;
      .news-title {
        height: 24px;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #4E4E4E;
      }
      .news-description {
        margin: 16px 0 24px;
        max-height: 50px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        line-height: 30px;
        color: #4E4E4E;
      }
      .news-time {
        height: 16px;
        font-size: 12px;
        font-family: Microsoft YaHei;
        color: #ACACAC;
      }
    }
  }
}

</style>
